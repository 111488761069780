const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      const auth = {
        isAuthenticated: true,
        token: action.token,
        expiresIn: action.expiresIn,
        tokenType: action.tokenType,
        lastUpdate: Date.now()
      };

      sessionStorage.setItem('auth', JSON.stringify(auth));
  
      return {
        ...state,
        auth,
      };
    case 'LOGOUT':
      sessionStorage.clear();
      return {
        ...state,
        auth: {
          isAuthenticated: false,
          token: null,
          expiresIn: null,
          type: null,
          lastUpdate: Date.now()
        },
        user: null
      };
    case 'SELECT_AUDIOBOOK':
      // start playback here
      return {
        ...state,
        selectedAudiobook: action.selectedAudiobook 
      }
    case 'SET_AUDIOBOOKS':
      return {
        ...state,
        audiobooks: action.audiobooks
      }
    case 'SET_ROOM':
      return {
        ...state,
        selectedRoom: action.roomId
      };
    case 'SET_DEVICES':
      return {
        ...state,
        devices: action.devices
      };
    case 'SET_PLAYBACK':
      return {
        ...state,
        playback: action.playback
      };
    case 'SET_USER':
        return {
          ...state,
          user: action.user
        };
    default:
      return state;
  }
};

export default reducer;