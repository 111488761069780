import React, { useState, useContext } from 'react';
import { AppContext } from './context';
import BackButton from './BackButton';
import { useHistory } from 'react-router-dom';
import Spotify from './spotifyService';

//import musiclogo from './assets/music_image_turquoise.png'; 

function AlbumInfo(props) {
  return (
  <React.Fragment>
    <div className="col-sm-11 col-md-4 mb-5">
      <img className="now-playing-album" src={props.album.images[0].url} alt="" />
    </div>
    <div className="col-sm-12 col-md-5 mb-5">
        <div className="now-playing__name">{props.album.name}</div>
        <div className="now-playing__artist">
          {props.album.artists[0].name}
        </div>
    </div>
  </React.Fragment>);

}

function AlbumRetrieval() {
  const { state, dispatch } = useContext(AppContext);
  const [album, setAlbum] = useState(null);
  const [uri, setUri] = useState('');
  const history = useHistory();

  const onBack = (e) => {
    history.push('/player');
  }

  const fetchAlbum = async (e) => {
    e.preventDefault();

    // do nothing if URI is empty
    if (uri === '') {
      return;
    }

    // uri --> id
    const id = uri.replace('spotify:album:', '');

    try {
      const album = await Spotify.getAlbum(state.auth.token, {id: id});
      console.info(album);
      setAlbum(album);
    } catch (err) {
      console.log(err);
    }
  }

  const info = album != null ? <AlbumInfo album={album}></AlbumInfo> : null;

  return (
    <div className="album-retrieval">
      <header className="row justify-content-center player-header">
        <h2 className="col-6">Rufe Information zu einem Hörspiel ab:</h2>
      </header>
      <div className="row">
        <hr className="col-4 solid"></hr>
      </div>
      <div className="row">
        <BackButton className="col-1 mb-5 align-self-center" onClick={onBack}></BackButton>
        <div className="col-sm11 col-md11">
          <form>
            <div className="form-row align-items-center">
              <div className="col-auto">
                <label className="sr-only" htmlFor="inlineFormInput">Spotify URI</label>
                <input type="text" className="form-control mb-2" id="inlineFormInput" placeholder="A56DF2334D" value={uri} onChange={e => setUri(e.target.value)} />
              </div>
              <div className="col-auto">
                <button type="submit" className="btn btn-primary mb-2" onClick={fetchAlbum}>Laden</button>
              </div>
            </div>
          </form>
        </div>
        { info }
      </div>
    </div>
  );
}

export default AlbumRetrieval;
