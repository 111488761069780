import React, { useRef, useEffect, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import { AvailableRooms } from './constants';
import { AppContext } from './context';
import Spotify from './spotifyService';

import './Rooms.scss';
import bedroomImage from './assets/bedroom_turquoise.png'; 
import livingroomImage from './assets/living_room_turquoise.png';
import kitchenImage from './assets/kitchen_turquoise.png';

const RoomList= [
  {
    image: livingroomImage,
    text: 'Wohnzimmer'
  },
  {
    image: bedroomImage,
    text: 'Schlafzimmer'
  },
  {
    image: kitchenImage,
    text: 'Küche'
  }
];

function Room(props) {
  function onClick (e) {
    e.preventDefault();
    props.onSelect(props.roomId);
  }

  const classes = `col-md room${props.isSelected ? ' selected' : ''}`;

  return (
    <div className={classes} onClick={onClick}>
      <img src={props.roomImage} alt={props.roomText} />
      <p>{props.roomText}</p>
    </div>
  );
}

function Rooms() {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  const lastGetPlaybackAbortController = useRef();

  useEffect(() => {
    async function getSpotifyData() {
      // abort old request in case we trigger a new one
      if (lastGetPlaybackAbortController.current) {
        lastGetPlaybackAbortController.current.abort();
      }

      // Create new AbortController for the new request and store it in the ref
      lastGetPlaybackAbortController.current = new AbortController();

      // get current playback
      try {
        const playback = await Spotify.getPlayback(state.auth.token, lastGetPlaybackAbortController.current.signal);
        console.info(playback);

        if (playback != null) {
          const activeDeviceId = playback.device.id;
          dispatch({
            type: 'SET_ROOM',
            roomId: activeDeviceId
          });
        }

        dispatch({
          type: 'SET_PLAYBACK',
          playback: playback
        });

        // Fetch all devices available for that account
        if (state.devices.length === 0) {
          const response = await Spotify.getUserDevices(state.auth.token);

          const speakers = response.devices.filter(device => device.type === 'Speaker');

          dispatch({
            type: 'SET_DEVICES',
            devices: speakers
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    getSpotifyData();
  }, [state.auth.token, state.selectedRoom, state.devices, dispatch]);

  function handleClick(roomId) {
    dispatch({
      type: 'SET_ROOM',
      roomId: roomId
    });

    history.push("/audiobooks");
  }

  const unkownRoomConnected = state.selectedRoom!= null && state.selectedRoom !== AvailableRooms.LivingRoom && state.selectedRoom !== AvailableRooms.Bedroom && state.selectedRoom !== AvailableRooms.Kitchen;

  let crRender = <p>Aktuell wird Musik auf einem anderen Gerät abgespielt</p>;
  if (unkownRoomConnected && state.devices.length > 0) {
    const connectedRoom = state.devices.find(d => d.id === state.selectedRoom);
    console.info(connectedRoom, state.devices);
    crRender = <p>Aktuell wird auf <span>{connectedRoom.name}</span> abgespielt.</p>;
  }

  return (
    <div className="rooms">
      <header className="row rooms-header">
        <h2 className="col">In welchem Raum möchtest du hören?</h2>
      </header>
      <div className="row justify-content-center">
        {
          state.devices.map(d => {
            // find room by matching name
            let room = RoomList.find(r => d.name.includes(r.text)); // d.name, d.id, d.isActive

            if (room == null) {
              return null;
            }

            return (
              <Room isSelected={d.id === state.selectedRoom} key={d.id} roomId={d.id} roomImage={room.image} roomText={room.text} onSelect={handleClick} />
            );
          })
        }
      </div>
      <div className="row justify-content-center">
      {unkownRoomConnected ? crRender : undefined}
      </div>
    </div>
  );
}

export default Rooms;
