import React, { useEffect, useState, useReducer } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import reducer from './reducer';
import { InitialState, AppContext } from './context';
import Login from './Login';
import Player from './Player';
import Audibooks from './Audiobooks';
import Rooms from './Rooms';
import AuthRedirect from './AuthRedirect';

import './App.scss';
import AlbumRetrieval from './AlbumRetrieval';

function PrivateRoute({ children, ...rest }) {
  const { state } = React.useContext(AppContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        state.auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function App() {
  const [state, dispatch] = useReducer(reducer, InitialState);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // get token from sessionStorage
    let auth = sessionStorage.getItem('auth');

    if (auth == null) {
      setLoading(false);
      return;
    }

    auth = JSON.parse(auth);

    if (Date.now() > auth.lastUpdate + Number.parseInt(auth.expiresIn) * 1000) {
      dispatch({
        type: 'LOGIN',
        token: auth.token,
        expiresIn: auth.expiresIn,
        tokenType: auth.tokenType,
      });
    }

    setLoading(false);
  }, [dispatch, setLoading]);

  if (isLoading) {
    return (
    <div className="container">
      <div className="col align-self-center justif-content-center">
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    );
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Router>
        <div className="App">
          <div className="container-fluid">
            <Switch>
              <Route exact path="/">
                <Login></Login>
              </Route>
              <Route exact path="/redirect">
                <AuthRedirect></AuthRedirect>
              </Route>
              <PrivateRoute path="/rooms">
                <Rooms />
              </PrivateRoute>
              <PrivateRoute path="/audiobooks">
                <Audibooks />
              </PrivateRoute>
              <PrivateRoute path="/player">
                <Player />
              </PrivateRoute>
              <PrivateRoute path="/fetch">
                <AlbumRetrieval />
              </PrivateRoute>
            </Switch>
          </div>
        </div>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
