import React from 'react';
import './BackButton.scss';

export default function BackButton(props) {
  return (
    <div className={props.className} onClick={props.onClick}>
      <div>
        <p className={`back-button ${props.small?'small':''}`}></p>
        {props.children}
      </div>
    </div>
  );
}