import React from 'react';
import { AuthEndpoint, ClientId, RedirectUri, Scopes } from './constants';
import './Login.scss';
import musiclogo from './assets/music_image_turquoise.png'; 

const spotifyURL = `${AuthEndpoint}?client_id=${ClientId}&redirect_uri=${RedirectUri}&scope=${Scopes.join("%20")}&response_type=token`; //&show_dialog=true`;

function Login() {

  return (
    <div className="login">
      <header className="row justify-content-center login-header">
        <h2 className="col-6">Pauls Spotify für Hörbücher</h2>
      </header>
      <div className="row">
        <hr className="col-4 solid"></hr>
      </div>
      <div className="row justify-content-center">
        <div className="col-4">
          <img className="img-fluid" src={musiclogo} alt="logo" />
        </div>
      </div>
      <div className="row justify-content-center">
        <a href={spotifyURL} className="btn btn-login">Anmelden</a>
      </div>
    </div>
  );
}

export default Login;
