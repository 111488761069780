import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackButton from './BackButton';
import { chunk } from 'lodash'
import { useHistory } from "react-router-dom";
import { AppContext } from './context';

import './AudioBooks.scss';
import Spotify from './spotifyService';
import { AudiobookPlaylistUri } from './constants';

// import overlay images
import { ReactComponent as PlayIcon } from './assets/play.svg';
import { ReactComponent as VolumeIcon } from './assets/volume-2.svg';

/**
 * Renders the given children in a responsive grid using the specified columns
 *
 * @param {Object} props expecting {Number} of columns and children
 * @returns {React.Fragment} rendered grid
 */
function Grid (props) {
  const colWidth = 12 / props.cols
  const rows = chunk(React.Children.toArray(props.children), props.cols)

  return (
    <React.Fragment>
      {
        rows.map((cols, i) => {
          return (<div key={`row-${i}`} className="row">
            {
              cols.map((col, j) => {
                return (<div key={`row-${i}-col-${j}`} className={`col-sm-12 col-md-${colWidth} mb-5`}>
                  {col}
                </div>);
              })
            }
          </div>);
        })
      }
    </React.Fragment>
  );
}
/**
 * Renders a single audiobook item showing mainly its cover
 *
 * @param {Object} props expecting {String} spotifyURI and {String} albumImage and {function} onSelectBook
 * @returns {React.Component} rendered audiobook
 */
function AudioBook(props) {
  const onClick = (e) => {props.onSelectBook(e, props.spotifyURI)};
  return (<div className="view" onClick={onClick}>
      <img className="audiobook-album" src={props.albumImage} alt="" />
      <div className={`mask justify-content-center align-items-center ${props.overlay === 'volume' ? 'd-flex': ''}`}>
        {props.overlay === 'play' ? <PlayIcon className="feather" /> : <VolumeIcon className="feather" />}
      </div>
    </div>);
}
/**
 * Retrieves and renders all audiobooks available in the specified source Spotify playlist
 *
 * @returns {React.Component} rendered list of available audiobooks
 */
function Audiobooks() {
  const { state, dispatch } = useContext(AppContext);
  const [ status, setStatus ] = useState('LOADING');
  const [ loadingError, setLoadingError ] = useState(null);
  let history = useHistory();

  useEffect(() => {
    // Fetch list of tracks from plalyist
    setStatus('LOADING');

    async function getAudiobooks() {
      try {
        let country = 'DE'; // default
        if (state.user != null) {
          country = state.user.country;
        }

        const result = await Spotify.getPlaylistItems(state.auth.token, {playlist_id: AudiobookPlaylistUri, fields: Spotify.getPlaylistItems.defaultFields, market: country});

        // take all the tracks and transform them into an array usable for us
        const books = result.items.filter(i => i.track.is_playable).map(i => {
          // get album from track
          return i.track.album;
        });

        dispatch({type: 'SET_AUDIOBOOKS', audiobooks: books})
        setStatus('LOADED');
      } catch(err) {
        setStatus('FAILED');
        setLoadingError(err);
        console.log(err);
      }
    };

    getAudiobooks();
  }, [dispatch, state.auth.token, state.user]);

  const onSelectBook = async (e, spotifyURI) => {
    dispatch({ type: 'SELECT_AUDIOBOOK', selectedAudiobook: spotifyURI });

    // tell spotify to start the playback
    try {
      await Spotify.transferPlayback(state.auth.token, {}, { device_ids: [state.selectedRoom] });

      if (spotifyURI == null) {
        // continue with current song
        await Spotify.startPlayback(state.auth.token, { device_id: state.selectedRoom });
      } else {
        // switch to new song
        await Spotify.startPlayback(state.auth.token, { device_id: state.selectedRoom }, { context_uri: spotifyURI });
      }

      // update playback
      const playback = await Spotify.getPlayback(state.auth.token);

      dispatch({
        type: 'SET_PLAYBACK',
        playback: playback
      });
    } catch (err) {
      console.log(err);
    }

    history.push('/player');
  }

  const onBack = (e) => {
    history.push('/rooms');
  }

  // Render content based on status (audiobooks are loadig, loaded or request failed)
  let content;
  let currentlyPlayingAlbum = null;

  if (state.playback != null) {
    const currentAlbum = state.playback.item.album;
    currentlyPlayingAlbum = <AudioBook overlay="volume" key={currentAlbum.id} onSelectBook={onSelectBook} albumImage={currentAlbum.images[0].url}></AudioBook>
  }

  switch (status) {
    case 'LOADED':
      content = (<Grid cols={4}>
        {currentlyPlayingAlbum}
        {
          state.audiobooks.map((book, i) => {
            return (<AudioBook overlay="play" key={i} onSelectBook={onSelectBook} spotifyURI={book.uri} albumImage={book.images[0].url}></AudioBook>);
          })
        }
      </Grid>);
      break;
    case 'FAILED':
      content = (
        <div className="col align-self-center">
          <p>Leider ist beim Laden der Hörbücher ein Fehler aufgetreten.</p>
      <code>{loadingError != null ? loadingError.toString() : 'Das sollte eigentlich nicht passieren...'}</code>
        </div>);
      break;
    case 'LOADING':
      content = (
        <div className="col align-self-center">
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="player">
      <header className="row justify-content-center audiobooks-header">
        <BackButton className="col-1 my-1" small onClick={onBack}></BackButton>
        <h2 className="col align-self-center">Tippe auf eines der Hörspiele, um loszulegen...</h2>
      </header>
      <div className="row">
        <hr className="col solid"></hr>
      </div>
      <div className="row">
      </div>
      {content}
    </div>
  );
}

export default Audiobooks;
