export const AvailableRooms = {
  LivingRoom: 'f871c920df0265b0e96fb6bfbbcb4739085e6f6d',
  Kitchen: '8e483d152cd543b46ca9b0077283e25339e85555',
  Bedroom: 'ecd3a00196eafb65077ecdd305471ce0f47a096e',
  None: null
};

export const AuthEndpoint = 'https://accounts.spotify.com/authorize';

// Replace with your app's client ID, redirect URI and desired scopes
export const ClientId = '5cb5a4303d064fdcac0ec98c0bd08c95';

let ruri;
if (process.env.NODE_ENV !== 'production') {
  ruri = 'http://localhost:3000/redirect';
} else {
  ruri = 'https://pauls-hoerbucher.onrender.com/redirect';
}

// localhost for development and render static hosting in production
export const RedirectUri = ruri;

/** 
 * Required scopes for Spotify
*/
export const Scopes = [
    "user-read-private", // required for getting country code for filtering unplayable tracks
    "user-read-currently-playing",
    "user-read-playback-state",
    'user-modify-playback-state'
];

/**
 * ID for spotify playlist that contains the first track of a desired audiobook
 */
export const AudiobookPlaylistUri = '3T3YgClGPgnCtwf1jH2frx';