import { pick } from 'lodash';

export function createQuery(payload, queryKeys) {
    const params = pick(payload, queryKeys);

    // prepare query params
    let query = Object.entries(params).map(([key, value]) => {
      if (value !== '' && value != null) {
        return `${key}=${encodeURIComponent(value)}`;
      }
  
      return '';
    }).join('&');

    if (query !== '') {
      query = '?' + query;
    }

    return query;
}

/**
 * Simple wrapper around the spotify API
 * https://github.com/gk4m/vue-spotify
 */
const Spotify = {};
Spotify.API_URL = 'https://api.spotify.com/v1';

Spotify.genericRequest = function (token, endpoint, body=null, method="GET", config={}) {
  const headers = {'Content-Type': 'application/json'};

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const requestConfig = {
    method: method,
    ...config,
    headers: {
      ...headers,
      ...config.headers,
    },
  }

  if (body != null) {
    requestConfig.body = JSON.stringify(body);
  }

  return window
    .fetch(`${Spotify.API_URL}/${endpoint}`, requestConfig)
    .then(async response => {
      if (response.status === 401) {
        // ToDo: we could pop-up an error message here
        console.error('token is not valid anymore');
      }

      const text = await response.text();
      let data;

      // do no parse json in case server answers with empty response (for !204 status code)
      if (response.status === 204 || text.length === 0) {
        data = null;
      } else {
        data = JSON.parse(text);
      }
    
      if (response.ok && ((config.signal != null && config.signal.aborted === false) || config.signal == null)) {
        return data;
      } else {
        return Promise.reject(data);
      }
    })
}

Spotify.getAlbum = function (token, queryParameters, payload={}, signal=null) {
  return Spotify.genericRequest(token, `albums/${queryParameters.id}`, null, 'GET', { signal });
}

Spotify.getUserDevices = function (token, signal=null) {
  return Spotify.genericRequest(token, 'me/player/devices', null, 'GET', { signal });
}

Spotify.getPlayback = function (token, signal=null) {
  return Spotify.genericRequest(token, 'me/player', null, 'GET', { signal });
}

Spotify.startPlayback = function (token, queryParameters, payload={}, signal=null) {
  const query = createQuery(queryParameters, ['device_id']);
  const body = pick(payload, ['context_uri', 'uris', 'offset', 'position_ms']);

  return Spotify.genericRequest(token, `me/player/play${query}`, body, 'PUT', { signal });
}

Spotify.transferPlayback = function (token, queryParameters, payload={}, signal=null) {
  const body = pick(payload, ['device_ids', 'play']);

  return Spotify.genericRequest(token, `me/player`, body, 'PUT', { signal });
}

Spotify.getPlaylistItems = function (token, queryParameters, payload={}, signal=null) {
  // pick only import payload props
  const query = createQuery(queryParameters, ['fields', 'limit', 'offset', 'market']);
  return Spotify.genericRequest(token, `playlists/${queryParameters.playlist_id}/tracks${query}`, null, 'GET', { signal });
}

Spotify.getPlaylistItems.defaultFields = 'items(track(name,href,id,uri,is_playable,album(name,href,id,uri,images,artists),artists(name)))';

Spotify.getCurrentUserProfil = function (token, queryParameters, payload={}, signal=null) {
  return Spotify.genericRequest(token, 'me', null, 'GET', { signal });
}

export default Spotify;