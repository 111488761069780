import React, { useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from './context';
import Spotify from './spotifyService';

const getHashInformation = (location) => {
  // Get the hash of the url
  return location.hash
  .substring(1)
  .split("&")
  .reduce(function(initial, item) {
    if (item) {
      const parts = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);
    }
    return initial;
  }, {});
}

const getQueryParameters = (location) => {
  return new URLSearchParams(location.search);
}

export default function AuthRedirect(props) {
  // ToDo: get token information out of hash and push to AppContext
  const { state, dispatch } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  let lastGetUserProfileAbortController = useRef();

  useEffect(() => {
    // okay, login was successful, now store token information
    const hashParams = getHashInformation(location);
    dispatch({
      type: 'LOGIN',
      token: hashParams.access_token,
      tokenType: hashParams.token_type,
      expiresIn: hashParams.expires_in 
    });

    async function getCurrentUserProfile() {
      // abort old request in case we trigger a new one
      if (lastGetUserProfileAbortController.current) {
        lastGetUserProfileAbortController.current.abort();
      }
  
      // Create new AbortController for the new request and store it in the ref
      lastGetUserProfileAbortController.current = new AbortController();
  
      try {
        const user = await Spotify.getCurrentUserProfil(hashParams.access_token, lastGetUserProfileAbortController.current.signal);
  
        dispatch({
          type: 'SET_USER',
          user: user
        });
      } catch (err) {
        console.log(err);
      }
    }

    getCurrentUserProfile();

    // navigate to room selection
    history.push('/rooms');
  });

  // check if error occured that is passed as query parameter error
  const queryParams = getQueryParameters(location);
  if (queryParams.get('error') != null) {
    return (
      <div>Anscheined ist beim Login etwas schief gegangen. Hol mal Papa!</div>
    );
  }

  return (<div>Der Login war erfolgreich und gleich geht es weiter.</div>);
}