import React from 'react';
import { AvailableRooms } from './constants';

export const AppContext = React.createContext();

export const InitialState = {
  auth: {
    isAuthenticated: false,
    token: null,
    tokenType: null,
    expiresIn: null
  },
  user: null,
  selectedRoom: AvailableRooms.None,
  selectedAudiobook: null,
  audiobooks: [],
  devices: [],
  playback: null
};
