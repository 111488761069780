import React, { useContext, useRef } from 'react';
import { AppContext } from './context';
import BackButton from './BackButton';
import { useHistory } from 'react-router-dom';
import Spotify from './spotifyService';
import { useInterval } from './utils';

import './Player.scss';

function Player() {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const lastGetPlaybackAbortController = useRef();

  async function getPlayback() {
    // abort old request in case we trigger a new one
    if (lastGetPlaybackAbortController.current) {
      lastGetPlaybackAbortController.current.abort();
    }

    // Create new AbortController for the new request and store it in the ref
    lastGetPlaybackAbortController.current = new AbortController();

    try {
      const playback = await Spotify.getPlayback(state.auth.token, lastGetPlaybackAbortController.current.signal);

      dispatch({
        type: 'SET_PLAYBACK',
        playback: playback
      });
    } catch (err) {
      console.log(err);
    }
  }

  // and then every 5 seconds
  useInterval(getPlayback, 5000);

  // get selected album
  let album = '';
  let artist = '';
  let track = '';
  let isPlaying = false;
  let progessWidth = 0;
  let albumImage = '';

  if (state.playback != null) {
    album = state.playback.item.album;
    artist = album.artists[0].name;
    track = state.playback.item.name;
    isPlaying = state.playback.is_playing;
    progessWidth = state.playback.progress_ms / state.playback.item.duration_ms * 100;
    albumImage = album.images[0].url;
  }

  const onBack = (e) => {
    history.push('/audiobooks');
  }

  return (
    <div className="player">
      <header className="row justify-content-center player-header">
        <h2 className="col-6">Aktueller Titel</h2>
      </header>
      <div className="row">
        <hr className="col-4 solid"></hr>
      </div>
      <div className="row">
        <BackButton className="col-1 mb-5 align-self-center" onClick={onBack}></BackButton>
        <div className="col-sm-11 col-md-4 mb-5">
          <img className="now-playing-album" src={albumImage} alt="" />
        </div>
        <div className="col-sm-12 col-md-5 mb-5">
  <div className="now-playing__name">{album.name} - {track}</div>
            <div className="now-playing__artist">
              {artist}
            </div>
            <div className="now-playing__status">
              {isPlaying ? "Playing" : "Paused"}
            </div>
            <div className="progress">
              <div className="progress-bar" role="progressbar" style={{width: `${progessWidth}%`}} aria-valuenow={progessWidth} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Player;
